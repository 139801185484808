/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** The `BigInt` scalar type represents non-fractional signed whole numeric values. */
  BigInt: { input: any; output: any };
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any };
  /** Decimal custom scalar type */
  Decimal: { input: any; output: any };
  GeoJSONPointScalar: { input: any; output: any };
};

export type AddRoleRequest = {
  assignments?: InputMaybe<Array<RoleAssignmentScalar>>;
  description: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type Admin = {
  __typename?: "Admin";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  role: Role;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type AdminAddRequest = {
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  roleId: Scalars["String"]["input"];
  status?: InputMaybe<UserStatus>;
};

export type AdminUpdateRequest = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  roleId?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<UserStatus>;
};

export type AuthLoginRequest = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type AuthLoginResponse = {
  __typename?: "AuthLoginResponse";
  accessToken?: Maybe<Scalars["String"]["output"]>;
  lastLogin?: Maybe<Scalars["DateTime"]["output"]>;
};

export type CmsLang = {
  __typename?: "CMSLang";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  enabled: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  values: Array<CmsValue>;
};

export type CmsPage = {
  __typename?: "CMSPage";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  sections: Array<CmsSection>;
  updatedAt: Scalars["DateTime"]["output"];
  values: Array<CmsValue>;
};

export type CmsSection = {
  __typename?: "CMSSection";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  page: CmsPage;
  updatedAt: Scalars["DateTime"]["output"];
  values: Array<CmsValue>;
};

export type CmsValue = {
  __typename?: "CMSValue";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  key: Scalars["String"]["output"];
  language: CmsLang;
  name: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  page: CmsPage;
  section: CmsSection;
  updatedAt: Scalars["DateTime"]["output"];
  value: Scalars["String"]["output"];
};

export type CmsValueInput = {
  key: Scalars["String"]["input"];
  languageName: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
  pageName: Scalars["String"]["input"];
  sectionName: Scalars["String"]["input"];
  value: Scalars["String"]["input"];
};

export type Campaign = {
  __typename?: "Campaign";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  filterEndDate?: Maybe<Scalars["DateTime"]["output"]>;
  filterStartDate?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  investorStatus: CampaignInvestorStatus;
  isActive: Scalars["Boolean"]["output"];
  name: Scalars["String"]["output"];
  scheduleType: CampaignScheduleType;
  scheduledAt?: Maybe<Scalars["DateTime"]["output"]>;
  status: CampaignStatus;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  users?: Maybe<Array<User>>;
};

export enum CampaignInvestorStatus {
  All = "ALL",
  Deposited = "DEPOSITED",
  Invested = "INVESTED",
  Pending = "PENDING",
  Unverified = "UNVERIFIED",
  Verified = "VERIFIED",
}

export enum CampaignScheduleType {
  Immediately = "IMMEDIATELY",
  Scheduled = "SCHEDULED",
}

export enum CampaignStatus {
  Expired = "EXPIRED",
  Failed = "FAILED",
  Inactive = "INACTIVE",
  InProgress = "IN_PROGRESS",
  Scheduled = "SCHEDULED",
  Sent = "SENT",
}

export type Card = {
  __typename?: "Card";
  brand: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  display_brand: Scalars["String"]["output"];
  exp_month: Scalars["Float"]["output"];
  exp_year: Scalars["Float"]["output"];
  funding: Scalars["String"]["output"];
  last4: Scalars["String"]["output"];
};

export type CheckAndSaveDeviceInput = {
  deviceId: Scalars["String"]["input"];
  deviceType: Scalars["String"]["input"];
  model: Scalars["String"]["input"];
  osVersion: Scalars["String"]["input"];
};

export type City = {
  __typename?: "City";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  excerpt?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  location?: Maybe<Scalars["GeoJSONPointScalar"]["output"]>;
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  propertyDescription?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type Coupon = {
  __typename?: "Coupon";
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  creator?: Maybe<Investor>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  expirationDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  incentive: CouponIncentive;
  referrals?: Maybe<Array<Referral>>;
  source?: Maybe<Array<Referral>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum CouponIncentive {
  BonusCredit = "BONUS_CREDIT",
  Discount = "DISCOUNT",
  Min_100 = "MIN_100",
}

export type CouponWithInvestorCount = {
  __typename?: "CouponWithInvestorCount";
  coupon: Coupon;
  totalInvestors: Scalars["Float"]["output"];
};

export type CreateCampaignRequest = {
  description: Scalars["String"]["input"];
  investorStatus: CampaignInvestorStatus;
  name: Scalars["String"]["input"];
  scheduleDate?: InputMaybe<Scalars["String"]["input"]>;
  scheduleType: CampaignScheduleType;
  signupEnd?: InputMaybe<Scalars["String"]["input"]>;
  signupStart?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
};

export type CreateCouponInput = {
  code: Scalars["String"]["input"];
  expirationDate?: InputMaybe<Scalars["String"]["input"]>;
  incentive?: InputMaybe<Scalars["String"]["input"]>;
  sourceId: Scalars["String"]["input"];
};

export type CreateSourceInput = {
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type Customer = {
  __typename?: "Customer";
  email?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  object: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
};

export type DeleteAdminRequest = {
  id: Scalars["String"]["input"];
};

export type DeleteRoleRequest = {
  id: Scalars["String"]["input"];
};

export type Device = {
  __typename?: "Device";
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  fcmToken?: Maybe<Scalars["String"]["output"]>;
  hideNotificationBar?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  model: Scalars["String"]["output"];
  osVersion: Scalars["String"]["output"];
  otp?: Maybe<Scalars["String"]["output"]>;
  otpExpiration?: Maybe<Scalars["DateTime"]["output"]>;
  status: Scalars["String"]["output"];
  turnNotificationsOn?: Maybe<Scalars["Boolean"]["output"]>;
  type: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type DocumentOrder = {
  id: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export type FilterInput = {
  field: Scalars["String"]["input"];
  operator: Scalars["String"]["input"];
  value?: InputMaybe<Scalars["String"]["input"]>;
  values?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type GetNotificationStatusResponse = {
  __typename?: "GetNotificationStatusResponse";
  hideNotificationBar: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  turnNotificationsOn: Scalars["Boolean"]["output"];
};

export enum HubSpotContactLifeCycleStatus {
  Customer = "CUSTOMER",
  Evangelist = "EVANGELIST",
  Lead = "LEAD",
  MarketingQualifiedLead = "MARKETING_QUALIFIED_LEAD",
  Opportunity = "OPPORTUNITY",
  Other = "OTHER",
  SalesQualifiedLead = "SALES_QUALIFIED_LEAD",
  Subscriber = "SUBSCRIBER",
}

export enum HubSpotDealStatus {
  ClosedLost = "CLOSED_LOST",
  Deposited = "DEPOSITED",
  Invested = "INVESTED",
  SignedUp = "SIGNED_UP",
  Verified = "VERIFIED",
}

export type HubSpotSync = {
  __typename?: "HubSpotSync";
  amount?: Maybe<Scalars["Float"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  dealId?: Maybe<Scalars["String"]["output"]>;
  dealStatus?: Maybe<HubSpotDealStatus>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  investor: Investor;
  pipelineId?: Maybe<Scalars["String"]["output"]>;
  status: HubSpotSyncStatus;
  transaction: Transaction;
  tries?: Maybe<Scalars["Float"]["output"]>;
  type?: Maybe<HubSpotSyncType>;
  updatedAt: Scalars["DateTime"]["output"];
  upsellDealStatus?: Maybe<Scalars["String"]["output"]>;
};

export enum HubSpotSyncStatus {
  Completed = "COMPLETED",
  Failed = "FAILED",
  InProgress = "IN_PROGRESS",
  Pending = "PENDING",
}

export enum HubSpotSyncType {
  Deposit = "DEPOSIT",
  Investment = "INVESTMENT",
}

export type ImageOrder = {
  id: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export enum InitiatorType {
  Admin = "Admin",
  Investor = "Investor",
}

export type Investor = {
  __typename?: "Investor";
  annualInvestmentLimit: Scalars["BigInt"]["output"];
  balance: Scalars["BigInt"]["output"];
  coupon?: Maybe<Coupon>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  firstDepositDate?: Maybe<Scalars["DateTime"]["output"]>;
  firstInvestmentDate?: Maybe<Scalars["DateTime"]["output"]>;
  hideNotificationBar?: Maybe<Scalars["Boolean"]["output"]>;
  hubSpotDealId?: Maybe<Scalars["String"]["output"]>;
  hubSpotDealStatus?: Maybe<HubSpotDealStatus>;
  hubSpotSyncStatus: HubSpotSyncStatus;
  hubSpotSyncTries?: Maybe<Scalars["Float"]["output"]>;
  hubSpotSyncs?: Maybe<Array<HubSpotSync>>;
  id: Scalars["String"]["output"];
  investmentApproach?: Maybe<Scalars["String"]["output"]>;
  investmentDuration?: Maybe<Scalars["Float"]["output"]>;
  invitesSent?: Maybe<Array<InvestorInvite>>;
  isDemo: Scalars["Boolean"]["output"];
  referenceNumber?: Maybe<Scalars["Float"]["output"]>;
  referrals?: Maybe<Array<Referral>>;
  rewardBalance?: Maybe<Scalars["Float"]["output"]>;
  solanaPublicKey?: Maybe<Scalars["String"]["output"]>;
  solanaSecretKey?: Maybe<Scalars["String"]["output"]>;
  solanaWalletAddress?: Maybe<Scalars["String"]["output"]>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  sumsubApplicantId?: Maybe<Scalars["String"]["output"]>;
  sumsubButtonIds?: Maybe<Array<Scalars["String"]["output"]>>;
  sumsubPrivateComment?: Maybe<Scalars["String"]["output"]>;
  sumsubPublicComment?: Maybe<Scalars["String"]["output"]>;
  sumsubRejectLabels?: Maybe<Array<Scalars["String"]["output"]>>;
  sumsubSandboxMode?: Maybe<Scalars["Boolean"]["output"]>;
  turnNotificationsOn?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
  veriffSessionId?: Maybe<Scalars["String"]["output"]>;
  veriffSessionUrl?: Maybe<Scalars["String"]["output"]>;
  verifiedDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type InvestorInvite = {
  __typename?: "InvestorInvite";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  invitedEmail?: Maybe<Scalars["String"]["output"]>;
  inviter: Investor;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  status: InvitationStatus;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InvestorLead = {
  __typename?: "InvestorLead";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["String"]["output"];
  expectInvestmentAmount?: Maybe<Scalars["Int"]["output"]>;
  fullName: Scalars["String"]["output"];
  hubSpotLifecycleStage?: Maybe<HubSpotContactLifeCycleStatus>;
  hubspotContactId?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  intercomLeadConversationId?: Maybe<Scalars["String"]["output"]>;
  intercomLeadId?: Maybe<Scalars["String"]["output"]>;
  phoneNumber: Scalars["String"]["output"];
  preferredProperty?: Maybe<Property>;
  updatedAt: Scalars["DateTime"]["output"];
  utm_campaign?: Maybe<Scalars["String"]["output"]>;
  utm_content?: Maybe<Scalars["String"]["output"]>;
  utm_id?: Maybe<Scalars["String"]["output"]>;
  utm_medium?: Maybe<Scalars["String"]["output"]>;
  utm_source?: Maybe<Scalars["String"]["output"]>;
  utm_term?: Maybe<Scalars["String"]["output"]>;
  utm_url: Scalars["String"]["output"];
};

export type InvestorLeadListResponse = {
  __typename?: "InvestorLeadListResponse";
  items: Array<InvestorLead>;
  total: Scalars["Int"]["output"];
};

export type InvestorUpdateRequest = {
  id: Scalars["String"]["input"];
  status?: InputMaybe<UserStatus>;
};

export enum InvitationStatus {
  Accepted = "Accepted",
  Declined = "Declined",
  Expired = "Expired",
  Invested = "Invested",
  Pending = "Pending",
  Withdrawn = "Withdrawn",
}

export type Mutation = {
  __typename?: "Mutation";
  addAdmin: User;
  addCampaign: Campaign;
  addFinancial: PropertyFinancials;
  addPasswordReset: Scalars["Boolean"]["output"];
  addProperty: Property;
  addPropertyType: PropertyType;
  addRent: PropertyRent;
  addRole: Role;
  addTimelineEvent: PropertyTimeline;
  checkAndSaveDevice: Scalars["Boolean"]["output"];
  createCoupon: Coupon;
  createSource: Source;
  deleteAdmin: User;
  deleteFinancial: Scalars["Boolean"]["output"];
  deletePropertyDocument: Scalars["Boolean"]["output"];
  deletePropertyImage: Scalars["Boolean"]["output"];
  deletePropertyType: PropertyType;
  deletePropertyVideo: Scalars["Boolean"]["output"];
  deleteRent: Scalars["Boolean"]["output"];
  deleteRole: Role;
  deleteTimelineEvent: Scalars["Boolean"]["output"];
  depositFiat?: Maybe<Transaction>;
  linkReferralToSource: Referral;
  login: AuthLoginResponse;
  markAsActive: Campaign;
  refundTransaction: Transaction;
  resetPassword: ResetPasswordResponse;
  saveFcmToken: SaveFcmTokenResponse;
  saveOrUpdateCMSValues: Scalars["Boolean"]["output"];
  sendEmailToLead: Scalars["Boolean"]["output"];
  sendOTP: Scalars["String"]["output"];
  sendSummaryEmail: Scalars["String"]["output"];
  sendUnverifiedReminderEmail: User;
  softDeleteLead?: Maybe<Array<InvestorLead>>;
  softDeleteProperty: Property;
  updateAdmin: User;
  updateAmenities: Array<PropertyAmenity>;
  updateBalance: Investor;
  updateCity: City;
  updateFinancial: PropertyFinancials;
  updateHideNotificationBar: SaveFcmTokenResponse;
  updateInvestor: User;
  updatePropertiesOrder: Array<Property>;
  updateProperty: Property;
  updatePropertyDocumentsOrder: Property;
  updatePropertyImagesOrder: Property;
  updatePropertyType: PropertyType;
  updatePropertyVideosOrder: Property;
  updateRent: PropertyRent;
  updateRole: Role;
  updateTimelineEvent: PropertyTimeline;
  verifyOTP: Scalars["Boolean"]["output"];
};

export type MutationAddAdminArgs = {
  data: AdminAddRequest;
};

export type MutationAddCampaignArgs = {
  data: CreateCampaignRequest;
};

export type MutationAddFinancialArgs = {
  data: PropertyAddFinancialRequest;
};

export type MutationAddPasswordResetArgs = {
  data: PasswordResetRequest;
};

export type MutationAddPropertyArgs = {
  data: PropertyAddRequest;
};

export type MutationAddPropertyTypeArgs = {
  name: Scalars["String"]["input"];
};

export type MutationAddRentArgs = {
  data: PropertyAddRentRequest;
};

export type MutationAddRoleArgs = {
  data: AddRoleRequest;
};

export type MutationAddTimelineEventArgs = {
  data: PropertyAddTimelineRequest;
};

export type MutationCheckAndSaveDeviceArgs = {
  checkAndSaveDeviceInput: CheckAndSaveDeviceInput;
};

export type MutationCreateCouponArgs = {
  data: CreateCouponInput;
};

export type MutationCreateSourceArgs = {
  createSource: CreateSourceInput;
};

export type MutationDeleteAdminArgs = {
  data: DeleteAdminRequest;
};

export type MutationDeleteFinancialArgs = {
  data: PropertyDeleteFinancialRequest;
};

export type MutationDeletePropertyDocumentArgs = {
  data: PropertyDeleteDocumentRequest;
};

export type MutationDeletePropertyImageArgs = {
  data: PropertyDeleteImageRequest;
};

export type MutationDeletePropertyTypeArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeletePropertyVideoArgs = {
  data: PropertyDeleteVideoRequest;
};

export type MutationDeleteRentArgs = {
  data: PropertyDeleteRentRequest;
};

export type MutationDeleteRoleArgs = {
  data: DeleteRoleRequest;
};

export type MutationDeleteTimelineEventArgs = {
  data: PropertyDeleteTimelineRequest;
};

export type MutationDepositFiatArgs = {
  amount: Scalars["Int"]["input"];
  reference?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["String"]["input"];
};

export type MutationLinkReferralToSourceArgs = {
  couponCode: Scalars["String"]["input"];
  investorId: Scalars["String"]["input"];
};

export type MutationLoginArgs = {
  data: AuthLoginRequest;
};

export type MutationMarkAsActiveArgs = {
  id: Scalars["String"]["input"];
};

export type MutationRefundTransactionArgs = {
  reason: Scalars["String"]["input"];
  transactionId: Scalars["String"]["input"];
};

export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};

export type MutationSaveFcmTokenArgs = {
  deviceId: Scalars["String"]["input"];
  fcmToken: Scalars["String"]["input"];
  turnNotificationsOn: Scalars["Boolean"]["input"];
};

export type MutationSaveOrUpdateCmsValuesArgs = {
  input: Array<CmsValueInput>;
};

export type MutationSendEmailToLeadArgs = {
  body: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  subject: Scalars["String"]["input"];
};

export type MutationSendOtpArgs = {
  deviceId: Scalars["String"]["input"];
};

export type MutationSendUnverifiedReminderEmailArgs = {
  id: Scalars["String"]["input"];
};

export type MutationSoftDeleteLeadArgs = {
  email: Scalars["String"]["input"];
};

export type MutationSoftDeletePropertyArgs = {
  id: Scalars["String"]["input"];
};

export type MutationUpdateAdminArgs = {
  data: AdminUpdateRequest;
};

export type MutationUpdateAmenitiesArgs = {
  data: PropertyUpdateAmenitiesRequest;
};

export type MutationUpdateBalanceArgs = {
  balance: Scalars["Int"]["input"];
  id: Scalars["String"]["input"];
};

export type MutationUpdateCityArgs = {
  updateCityInput: UpdateCityInput;
};

export type MutationUpdateFinancialArgs = {
  data: PropertyUpdateFinancialRequest;
};

export type MutationUpdateHideNotificationBarArgs = {
  deviceId: Scalars["String"]["input"];
  hideNotificationBar: Scalars["Boolean"]["input"];
};

export type MutationUpdateInvestorArgs = {
  data: InvestorUpdateRequest;
};

export type MutationUpdatePropertiesOrderArgs = {
  data: Array<PropertiesOrderUpdateRequest>;
};

export type MutationUpdatePropertyArgs = {
  data: PropertyUpdateRequest;
};

export type MutationUpdatePropertyDocumentsOrderArgs = {
  data: PropertyDocumentOrderUpdateRequest;
};

export type MutationUpdatePropertyImagesOrderArgs = {
  data: PropertyImageOrderUpdateRequest;
};

export type MutationUpdatePropertyTypeArgs = {
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type MutationUpdatePropertyVideosOrderArgs = {
  data: PropertyVideoOrderUpdateRequest;
};

export type MutationUpdateRentArgs = {
  data: PropertyUpdateRentRequest;
};

export type MutationUpdateRoleArgs = {
  data: UpdateRoleRequest;
};

export type MutationUpdateTimelineEventArgs = {
  data: PropertyUpdateTimelineRequest;
};

export type MutationVerifyOtpArgs = {
  otp: Scalars["String"]["input"];
};

export type PaginatedDespositResponse = {
  __typename?: "PaginatedDespositResponse";
  items: Array<Transaction>;
  skip?: Maybe<Scalars["Int"]["output"]>;
  sumTotal: Scalars["Int"]["output"];
  take?: Maybe<Scalars["Int"]["output"]>;
  total: Scalars["Int"]["output"];
};

export type PaginatedUserResponse = {
  __typename?: "PaginatedUserResponse";
  items: Array<UserTotals>;
  skip?: Maybe<Scalars["Int"]["output"]>;
  take?: Maybe<Scalars["Int"]["output"]>;
  total: Scalars["Int"]["output"];
};

/** Common pagination request options */
export type PaginationRequest = {
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type Partner = {
  __typename?: "Partner";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type PasswordResetRequest = {
  email: Scalars["String"]["input"];
};

export type PaymentIntentNextAction = {
  __typename?: "PaymentIntentNextAction";
  type?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type PaymentMethod = {
  __typename?: "PaymentMethod";
  card?: Maybe<Card>;
  created: Scalars["Float"]["output"];
  customer: Customer;
  id: Scalars["String"]["output"];
  sepa_debit?: Maybe<SepaDebit>;
  type: Scalars["String"]["output"];
};

export type PledgeByIdResponse = {
  __typename?: "PledgeByIdResponse";
  items: Array<PropertyPledge>;
  total: Scalars["Int"]["output"];
};

export type PropertiesOrderUpdateRequest = {
  id: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export type Property = {
  __typename?: "Property";
  address: Scalars["String"]["output"];
  agreementPrice: Scalars["BigInt"]["output"];
  amenities?: Maybe<Array<PropertyAmenity>>;
  bath: Scalars["Int"]["output"];
  bed: Scalars["Int"]["output"];
  bookmarks?: Maybe<Array<PropertyBookmark>>;
  city: City;
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  documents?: Maybe<Array<PropertyFile>>;
  excerpt: Scalars["String"]["output"];
  finalInvestorsCount: Scalars["Int"]["output"];
  financials: Array<PropertyFinancials>;
  fundingCompleteDate?: Maybe<Scalars["DateTime"]["output"]>;
  fundingDeadlineDate: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  images?: Maybe<Array<PropertyFile>>;
  isFunded: Scalars["Boolean"]["output"];
  isRented: Scalars["Boolean"]["output"];
  leads?: Maybe<Array<InvestorLead>>;
  location?: Maybe<Scalars["GeoJSONPointScalar"]["output"]>;
  manualFunded?: Maybe<Scalars["BigInt"]["output"]>;
  manualFundedDays?: Maybe<Scalars["BigInt"]["output"]>;
  minimumInvestment: Scalars["Int"]["output"];
  order: Scalars["Int"]["output"];
  platformFee: Scalars["BigInt"]["output"];
  pledges: Array<PropertyPledge>;
  price: Scalars["BigInt"]["output"];
  rents: Array<PropertyRent>;
  serviceCharges: Scalars["BigInt"]["output"];
  slug: Scalars["String"]["output"];
  solanaMintAddress?: Maybe<Scalars["String"]["output"]>;
  sqFootage: Scalars["Int"]["output"];
  status: PropertyStatus;
  timelineEvents: Array<PropertyTimeline>;
  title: Scalars["String"]["output"];
  transactionFee: Scalars["BigInt"]["output"];
  type?: Maybe<PropertyType>;
  updatedAt: Scalars["DateTime"]["output"];
  videos?: Maybe<Array<PropertyFile>>;
};

export type PropertyAddFinancialRequest = {
  annualRent: Scalars["BigInt"]["input"];
  effectiveDate: Scalars["String"]["input"];
  isMandatory: Scalars["Boolean"]["input"];
  projectedAnnualRoIPercentage: Scalars["Int"]["input"];
  projectedAnnualRoIValue: Scalars["BigInt"]["input"];
  propertyId: Scalars["String"]["input"];
  year2ProjectedAnnualRoIPercentage: Scalars["Int"]["input"];
  year2ProjectedAnnualRoIValue: Scalars["BigInt"]["input"];
  year3ProjectedAnnualRoIPercentage: Scalars["Int"]["input"];
  year3ProjectedAnnualRoIValue: Scalars["BigInt"]["input"];
};

export type PropertyAddRentRequest = {
  date?: InputMaybe<Scalars["String"]["input"]>;
  isPaid: Scalars["Boolean"]["input"];
  propertyId: Scalars["String"]["input"];
  rent: Scalars["BigInt"]["input"];
};

export type PropertyAddRequest = {
  address: Scalars["String"]["input"];
  agreementPrice: Scalars["BigInt"]["input"];
  bath: Scalars["Int"]["input"];
  bed: Scalars["Int"]["input"];
  city: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  excerpt: Scalars["String"]["input"];
  fundingDeadlineDate: Scalars["DateTime"]["input"];
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
  manualFunded?: InputMaybe<Scalars["BigInt"]["input"]>;
  minimumInvestment: Scalars["Int"]["input"];
  serviceCharges: Scalars["BigInt"]["input"];
  sqFootage: Scalars["Int"]["input"];
  status: PropertyStatus;
  title: Scalars["String"]["input"];
  transactionFee: Scalars["BigInt"]["input"];
  type: Scalars["String"]["input"];
};

export type PropertyAddTimelineRequest = {
  date: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  propertyId: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type PropertyAmenity = {
  __typename?: "PropertyAmenity";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  property: Property;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PropertyBookmark = {
  __typename?: "PropertyBookmark";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  property: Property;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type PropertyDeleteDocumentRequest = {
  documentId: Scalars["String"]["input"];
  propertyId: Scalars["String"]["input"];
};

export type PropertyDeleteFinancialRequest = {
  id: Scalars["String"]["input"];
};

export type PropertyDeleteImageRequest = {
  imageId: Scalars["String"]["input"];
  propertyId: Scalars["String"]["input"];
};

export type PropertyDeleteRentRequest = {
  id: Scalars["String"]["input"];
};

export type PropertyDeleteTimelineRequest = {
  id: Scalars["String"]["input"];
};

export type PropertyDeleteVideoRequest = {
  propertyId: Scalars["String"]["input"];
  videoId: Scalars["String"]["input"];
};

export type PropertyDocumentOrderUpdateRequest = {
  documents: Array<DocumentOrder>;
  propertyId: Scalars["String"]["input"];
};

export type PropertyFile = {
  __typename?: "PropertyFile";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  ext: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  mimetype: Scalars["String"]["output"];
  order: Scalars["Int"]["output"];
  propertyDocument: Property;
  propertyImage: Property;
  propertyVideo: Property;
  size: Scalars["BigInt"]["output"];
  type: PropertyFileType;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum PropertyFileType {
  Document = "Document",
  Image = "Image",
  Video = "Video",
}

export type PropertyFinancials = {
  __typename?: "PropertyFinancials";
  annualAppreciation: Scalars["Int"]["output"];
  annualManagementAndMaintenanceCharges: Scalars["BigInt"]["output"];
  annualRent: Scalars["BigInt"]["output"];
  annualServiceCharges: Scalars["BigInt"]["output"];
  annualizedReturn: Scalars["Int"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  effectiveDate: Scalars["DateTime"]["output"];
  endDate?: Maybe<Scalars["DateTime"]["output"]>;
  estimated3YrRoI?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["String"]["output"];
  isMandatory?: Maybe<Scalars["Boolean"]["output"]>;
  projectedAnnualRoIPercentage: Scalars["BigInt"]["output"];
  projectedAnnualRoIValue: Scalars["BigInt"]["output"];
  projectedGrossYield: Scalars["Int"]["output"];
  projectedNetYield: Scalars["Int"]["output"];
  property: Property;
  updatedAt: Scalars["DateTime"]["output"];
  year2ProjectedAnnualRoIPercentage: Scalars["BigInt"]["output"];
  year2ProjectedAnnualRoIValue: Scalars["BigInt"]["output"];
  year3ProjectedAnnualRoIPercentage: Scalars["BigInt"]["output"];
  year3ProjectedAnnualRoIValue: Scalars["BigInt"]["output"];
};

export type PropertyImageOrderUpdateRequest = {
  images: Array<ImageOrder>;
  propertyId: Scalars["String"]["input"];
};

export type PropertyPledge = {
  __typename?: "PropertyPledge";
  amount: Scalars["BigInt"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  property: Property;
  status: PropertyPledgeStatus;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export enum PropertyPledgeStatus {
  Cancelled = "Cancelled",
  Confirmed = "Confirmed",
  Pending = "Pending",
  Shareholder = "Shareholder",
}

export type PropertyRent = {
  __typename?: "PropertyRent";
  createdAt: Scalars["DateTime"]["output"];
  date?: Maybe<Scalars["DateTime"]["output"]>;
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  isDisbursed: Scalars["Boolean"]["output"];
  isPaid: Scalars["Boolean"]["output"];
  property: Property;
  rent: Scalars["BigInt"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum PropertyStatus {
  Active = "Active",
  ComingSoon = "ComingSoon",
  Completed = "Completed",
  Inactive = "Inactive",
  Pending = "Pending",
}

export type PropertyTimeline = {
  __typename?: "PropertyTimeline";
  createdAt: Scalars["DateTime"]["output"];
  date: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  property: Property;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type PropertyType = {
  __typename?: "PropertyType";
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  properties: Array<Property>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type PropertyUpdateAmenitiesRequest = {
  amenities: Array<Scalars["String"]["input"]>;
  propertyId: Scalars["String"]["input"];
};

export type PropertyUpdateFinancialRequest = {
  annualRent: Scalars["BigInt"]["input"];
  effectiveDate: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  isMandatory: Scalars["Boolean"]["input"];
  projectedAnnualRoIPercentage: Scalars["Int"]["input"];
  projectedAnnualRoIValue: Scalars["BigInt"]["input"];
  propertyId: Scalars["String"]["input"];
  year2ProjectedAnnualRoIPercentage: Scalars["Int"]["input"];
  year2ProjectedAnnualRoIValue: Scalars["BigInt"]["input"];
  year3ProjectedAnnualRoIPercentage: Scalars["Int"]["input"];
  year3ProjectedAnnualRoIValue: Scalars["BigInt"]["input"];
};

export type PropertyUpdateRentRequest = {
  date?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  isPaid: Scalars["Boolean"]["input"];
  propertyId: Scalars["String"]["input"];
  rent: Scalars["BigInt"]["input"];
};

export type PropertyUpdateRequest = {
  address: Scalars["String"]["input"];
  agreementPrice: Scalars["BigInt"]["input"];
  bath: Scalars["Int"]["input"];
  bed: Scalars["Int"]["input"];
  city: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  excerpt: Scalars["String"]["input"];
  fundingDeadlineDate: Scalars["DateTime"]["input"];
  id: Scalars["String"]["input"];
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
  manualFunded?: InputMaybe<Scalars["BigInt"]["input"]>;
  manualFundedDays?: InputMaybe<Scalars["BigInt"]["input"]>;
  minimumInvestment: Scalars["Int"]["input"];
  serviceCharges: Scalars["BigInt"]["input"];
  sqFootage: Scalars["Int"]["input"];
  status: PropertyStatus;
  title: Scalars["String"]["input"];
  transactionFee: Scalars["BigInt"]["input"];
  type: Scalars["String"]["input"];
};

export type PropertyUpdateTimelineRequest = {
  date: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  propertyId: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type PropertyVideoOrderUpdateRequest = {
  propertyId: Scalars["String"]["input"];
  videos: Array<VideoOrder>;
};

export type Query = {
  __typename?: "Query";
  campaign: Campaign;
  campaigns: Array<Campaign>;
  cities: Array<City>;
  deposits: PaginatedDespositResponse;
  getAllCoupons: Array<Coupon>;
  getAllSources: Array<Source>;
  getAllSourcesWithTotals: Array<SourceWithTotals>;
  getCMSValues: Array<CmsValue>;
  getCouponsBySourceId: Array<CouponWithInvestorCount>;
  getInterestedInvestors: InvestorLeadListResponse;
  getNotificationStatus: GetNotificationStatusResponse;
  getPropertyPledgeById: PledgeByIdResponse;
  getSourceById?: Maybe<Source>;
  getTransactionById: TransactionByIdResponse;
  getUnPaginatedInterestedInvestors: InvestorLeadListResponse;
  getUserByReferralAndCoupon: Array<UserWithCoupon>;
  getUsersByCoupon: UserAndCoupon;
  investments: PaginatedDespositResponse;
  listPaymentMethods: Array<PaymentMethod>;
  me: User;
  myRole: Role;
  properties: Array<Property>;
  property: Property;
  propertyTypes: Array<PropertyType>;
  roleById: Role;
  roles: Array<Role>;
  unPaginatedUsers: PaginatedUserResponse;
  userById: User;
  /** Checks if a user with the given email exists. */
  userExists: Scalars["Boolean"]["output"];
  users: PaginatedUserResponse;
  verifyDevice: Scalars["Boolean"]["output"];
};

export type QueryCampaignArgs = {
  id: Scalars["String"]["input"];
};

export type QueryDepositsArgs = {
  endDate: Scalars["String"]["input"];
  paginationOptions: PaginationRequest;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["String"]["input"];
};

export type QueryGetCouponsBySourceIdArgs = {
  sourceId: Scalars["String"]["input"];
};

export type QueryGetInterestedInvestorsArgs = {
  endDate: Scalars["String"]["input"];
  hubSpotLifecycleStage?: InputMaybe<HubSpotContactLifeCycleStatus>;
  paginationOptions: PaginationRequest;
  startDate: Scalars["String"]["input"];
};

export type QueryGetNotificationStatusArgs = {
  deviceId: Scalars["String"]["input"];
};

export type QueryGetPropertyPledgeByIdArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetSourceByIdArgs = {
  id: Scalars["String"]["input"];
};

export type QueryGetTransactionByIdArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  take?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetUnPaginatedInterestedInvestorsArgs = {
  endDate: Scalars["String"]["input"];
  startDate: Scalars["String"]["input"];
};

export type QueryGetUserByReferralAndCouponArgs = {
  sourceId: Scalars["String"]["input"];
};

export type QueryGetUsersByCouponArgs = {
  couponId: Scalars["String"]["input"];
};

export type QueryInvestmentsArgs = {
  endDate: Scalars["String"]["input"];
  paginationOptions: PaginationRequest;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["String"]["input"];
};

export type QueryListPaymentMethodsArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryMeArgs = {
  deviceId: Scalars["String"]["input"];
};

export type QueryPropertiesArgs = {
  paginationOptions: PaginationRequest;
  where?: InputMaybe<WhereInput>;
};

export type QueryPropertyArgs = {
  id: Scalars["String"]["input"];
};

export type QueryRoleByIdArgs = {
  id: Scalars["String"]["input"];
};

export type QueryRolesArgs = {
  paginationOptions: PaginationRequest;
  where?: InputMaybe<WhereInput>;
};

export type QueryUnPaginatedUsersArgs = {
  endDate: Scalars["String"]["input"];
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["String"]["input"];
  where?: InputMaybe<WhereInput>;
};

export type QueryUserByIdArgs = {
  id: Scalars["String"]["input"];
};

export type QueryUserExistsArgs = {
  email: Scalars["String"]["input"];
};

export type QueryUsersArgs = {
  endDate: Scalars["String"]["input"];
  paginationOptions: PaginationRequest;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
  startDate: Scalars["String"]["input"];
  where?: InputMaybe<WhereInput>;
};

export type QueryVerifyDeviceArgs = {
  deviceId: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
};

export type Referral = {
  __typename?: "Referral";
  coupon?: Maybe<Coupon>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  referralDate?: Maybe<Scalars["DateTime"]["output"]>;
  referralRewardClaimed?: Maybe<Scalars["Boolean"]["output"]>;
  referredInvestor: Investor;
  referrerInvestor?: Maybe<Investor>;
  rewardStatus: ReferralRewardStatus;
  source: Source;
  updatedAt: Scalars["DateTime"]["output"];
};

export enum ReferralRewardStatus {
  Claimed = "CLAIMED",
  Expired = "EXPIRED",
  Granted = "GRANTED",
  Pending = "PENDING",
}

export type ResetPasswordInput = {
  code: Scalars["String"]["input"];
  id: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
};

export type ResetPasswordResponse = {
  __typename?: "ResetPasswordResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type Role = {
  __typename?: "Role";
  admins: Array<Admin>;
  assignments: Array<RoleAssignment>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export enum RoleAction {
  Add = "Add",
  Block = "Block",
  Delete = "Delete",
  List = "List",
  ListActive = "ListActive",
  ListAvailable = "ListAvailable",
  ListBlocked = "ListBlocked",
  ListCompleted = "ListCompleted",
  ListCustomer = "ListCustomer",
  ListDeposited = "ListDeposited",
  ListDepositedNotInvested = "ListDepositedNotInvested",
  ListEvangelist = "ListEvangelist",
  ListExited = "ListExited",
  ListHighBalance = "ListHighBalance",
  ListInactive = "ListInactive",
  ListInvested = "ListInvested",
  ListLead = "ListLead",
  ListMarketingQualifiedLead = "ListMarketingQualifiedLead",
  ListNew = "ListNew",
  ListOpportunity = "ListOpportunity",
  ListOther = "ListOther",
  ListReferrals = "ListReferrals",
  ListRented = "ListRented",
  ListSalesQualifiedLead = "ListSalesQualifiedLead",
  ListSubscriber = "ListSubscriber",
  ListUnverified = "ListUnverified",
  Unblock = "Unblock",
  UnverifiedReminderEmail = "UnverifiedReminderEmail",
  Update = "Update",
  View = "View",
}

export type RoleAssignment = {
  __typename?: "RoleAssignment";
  action: RoleAction;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  module: RoleModule;
  role: Role;
  updatedAt: Scalars["DateTime"]["output"];
};

export type RoleAssignmentScalar = {
  action: RoleAction;
  module: RoleModule;
};

export enum RoleModule {
  Admin = "Admin",
  Analytics = "Analytics",
  Campaign = "Campaign",
  Cities = "Cities",
  Cms = "Cms",
  Investor = "Investor",
  Leads = "Leads",
  Property = "Property",
  Referral = "Referral",
  Rewards = "Rewards",
  Role = "Role",
  Transaction = "Transaction",
}

export type SaveFcmTokenResponse = {
  __typename?: "SaveFcmTokenResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type SepaDebit = {
  __typename?: "SepaDebit";
  bank_code: Scalars["String"]["output"];
  branch_code: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  fingerprint: Scalars["String"]["output"];
  last4: Scalars["String"]["output"];
};

export type Source = {
  __typename?: "Source";
  coupons?: Maybe<Array<Coupon>>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  email: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  referrals?: Maybe<Array<Referral>>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type SourceWithTotals = {
  __typename?: "SourceWithTotals";
  source: Source;
  totalCoupons: Scalars["Int"]["output"];
  totalInvestors: Scalars["Int"]["output"];
};

export type Transaction = {
  __typename?: "Transaction";
  amount: Scalars["BigInt"]["output"];
  ccFee?: Maybe<Scalars["Decimal"]["output"]>;
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  cryptoFxRate?: Maybe<Scalars["Decimal"]["output"]>;
  cryptoTag?: Maybe<Scalars["BigInt"]["output"]>;
  cryptoWallet?: Maybe<Scalars["String"]["output"]>;
  cryptofxRateWithMarkup?: Maybe<Scalars["Decimal"]["output"]>;
  currency: Scalars["String"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  finalisedAt?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["String"]["output"];
  initiatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  initiatedBy: InitiatorType;
  initiatedByUser?: Maybe<Scalars["String"]["output"]>;
  paymentIntentId?: Maybe<Scalars["String"]["output"]>;
  paymentMethodId?: Maybe<Scalars["String"]["output"]>;
  paymentReferenceId?: Maybe<Scalars["String"]["output"]>;
  property?: Maybe<Property>;
  refundedAt?: Maybe<Scalars["DateTime"]["output"]>;
  refundedReason?: Maybe<Scalars["String"]["output"]>;
  relatedId?: Maybe<Scalars["String"]["output"]>;
  relatedType?: Maybe<Scalars["String"]["output"]>;
  solanaDepositTransactionId?: Maybe<Scalars["String"]["output"]>;
  solanaPropertyTransactionId?: Maybe<Scalars["String"]["output"]>;
  solanaSyncedAt?: Maybe<Scalars["DateTime"]["output"]>;
  solanaTransactionId?: Maybe<Scalars["String"]["output"]>;
  source: TransactionSource;
  status: TransactionStatus;
  stripeFee?: Maybe<Scalars["Decimal"]["output"]>;
  totalAmount?: Maybe<Scalars["Decimal"]["output"]>;
  type: TransactionType;
  updatedAt: Scalars["DateTime"]["output"];
  user: User;
};

export type TransactionByIdResponse = {
  __typename?: "TransactionByIdResponse";
  items: Array<Transaction>;
  total: Scalars["Int"]["output"];
};

export enum TransactionSource {
  BankTransfer = "BankTransfer",
  KingdomBank = "KingdomBank",
  Stripe = "Stripe",
}

export enum TransactionStatus {
  Canceled = "Canceled",
  Confirmed = "Confirmed",
  ErrorSolana = "ErrorSolana",
  Failed = "Failed",
  Pending = "Pending",
  PendingSolana = "PendingSolana",
  ProcessingSolana = "ProcessingSolana",
  Refunded = "Refunded",
}

export enum TransactionType {
  Coupon = "Coupon",
  Deposit = "Deposit",
  Pledge = "Pledge",
  RentDisbursement = "RentDisbursement",
  Withdrawal = "Withdrawal",
}

export type UpdateCityInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  excerpt?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  latitude?: InputMaybe<Scalars["Float"]["input"]>;
  longitude?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  propertyDescription?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateRoleRequest = {
  assignments?: InputMaybe<Array<RoleAssignmentScalar>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type User = {
  __typename?: "User";
  adminProfile?: Maybe<Admin>;
  bookmarks?: Maybe<Array<PropertyBookmark>>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  devices?: Maybe<Array<Device>>;
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  investorProfile?: Maybe<Investor>;
  lastLogin?: Maybe<Scalars["DateTime"]["output"]>;
  lastName: Scalars["String"]["output"];
  marketingEmails?: Maybe<Scalars["Boolean"]["output"]>;
  partnerProfile?: Maybe<Partner>;
  password?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  status: UserStatus;
  updatedAt: Scalars["DateTime"]["output"];
  userType: UserType;
  verifiedEmail?: Maybe<Scalars["Boolean"]["output"]>;
  verifiedPhone?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UserAndCoupon = {
  __typename?: "UserAndCoupon";
  coupon: Scalars["String"]["output"];
  users: Array<User>;
};

export enum UserStatus {
  Active = "Active",
  Blocked = "Blocked",
  Unverified = "Unverified",
  VerificationInProgress = "VerificationInProgress",
}

export type UserTotals = {
  __typename?: "UserTotals";
  adminProfile?: Maybe<Admin>;
  bookmarks?: Maybe<Array<PropertyBookmark>>;
  createdAt: Scalars["DateTime"]["output"];
  deletedAt?: Maybe<Scalars["DateTime"]["output"]>;
  devices?: Maybe<Array<Device>>;
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  investorProfile?: Maybe<Investor>;
  lastLogin?: Maybe<Scalars["DateTime"]["output"]>;
  lastName: Scalars["String"]["output"];
  marketingEmails?: Maybe<Scalars["Boolean"]["output"]>;
  partnerProfile?: Maybe<Partner>;
  password?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  status: UserStatus;
  totalDeposits?: Maybe<Scalars["Int"]["output"]>;
  totalInvestments?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  userType: UserType;
  verifiedEmail?: Maybe<Scalars["Boolean"]["output"]>;
  verifiedPhone?: Maybe<Scalars["Boolean"]["output"]>;
};

export enum UserType {
  Admin = "Admin",
  Investor = "Investor",
  Partner = "Partner",
}

export type UserWithCoupon = {
  __typename?: "UserWithCoupon";
  coupon: Scalars["String"]["output"];
  user: User;
};

export type VideoOrder = {
  id: Scalars["String"]["input"];
  order: Scalars["Int"]["input"];
};

export type WhereInput = {
  filters?: InputMaybe<Array<FilterInput>>;
};

export type UpdateAdminMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<UserStatus>;
  roleId: Scalars["String"]["input"];
}>;

export type UpdateAdminMutation = {
  __typename?: "Mutation";
  updateAdmin: { __typename?: "User"; id: string };
};

export type AddUserMutationVariables = Exact<{
  firstName: Scalars["String"]["input"];
  lastName: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
  status?: InputMaybe<UserStatus>;
  roleId: Scalars["String"]["input"];
}>;

export type AddUserMutation = {
  __typename?: "Mutation";
  addAdmin: { __typename?: "User"; id: string };
};

export type DeleteAdminMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteAdminMutation = {
  __typename?: "Mutation";
  deleteAdmin: { __typename?: "User"; id: string };
};

export type SendUnverifiedReminderEmailMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type SendUnverifiedReminderEmailMutation = {
  __typename?: "Mutation";
  sendUnverifiedReminderEmail: { __typename?: "User"; id: string };
};

export type UpdateAmenitiesMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  amenities: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type UpdateAmenitiesMutation = {
  __typename?: "Mutation";
  updateAmenities: Array<{ __typename?: "PropertyAmenity"; id: string }>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login: { __typename?: "AuthLoginResponse"; accessToken?: string | null };
};

export type AddCampaignMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  scheduleType: CampaignScheduleType;
  scheduleDate?: InputMaybe<Scalars["String"]["input"]>;
  investorStatus: CampaignInvestorStatus;
  signupStart?: InputMaybe<Scalars["String"]["input"]>;
  signupEnd?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AddCampaignMutation = {
  __typename?: "Mutation";
  addCampaign: {
    __typename?: "Campaign";
    id: string;
    name: string;
    description: string;
  };
};

export type MarkCampaignAsActiveMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type MarkCampaignAsActiveMutation = {
  __typename?: "Mutation";
  markAsActive: {
    __typename?: "Campaign";
    id: string;
    name: string;
    description: string;
  };
};

export type UpdateCityMutationVariables = Exact<{
  updateCityInput: UpdateCityInput;
}>;

export type UpdateCityMutation = {
  __typename?: "Mutation";
  updateCity: {
    __typename?: "City";
    id: string;
    name: string;
    excerpt?: string | null;
    description?: string | null;
    propertyDescription?: string | null;
    location?: any | null;
  };
};

export type SaveOrUpdateCmsValuesMutationVariables = Exact<{
  input: Array<CmsValueInput> | CmsValueInput;
}>;

export type SaveOrUpdateCmsValuesMutation = {
  __typename?: "Mutation";
  saveOrUpdateCMSValues: boolean;
};

export type CheckAndSaveDeviceMutationVariables = Exact<{
  deviceId: Scalars["String"]["input"];
  osVersion: Scalars["String"]["input"];
  deviceType: Scalars["String"]["input"];
  model: Scalars["String"]["input"];
}>;

export type CheckAndSaveDeviceMutation = {
  __typename?: "Mutation";
  checkAndSaveDevice: boolean;
};

export type AddFinancialMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  projectedAnnualRoIPercentage: Scalars["Int"]["input"];
  projectedAnnualRoIValue: Scalars["BigInt"]["input"];
  year2ProjectedAnnualRoIPercentage: Scalars["Int"]["input"];
  year2ProjectedAnnualRoIValue: Scalars["BigInt"]["input"];
  year3ProjectedAnnualRoIPercentage: Scalars["Int"]["input"];
  year3ProjectedAnnualRoIValue: Scalars["BigInt"]["input"];
  annualRent: Scalars["BigInt"]["input"];
  effectiveDate: Scalars["String"]["input"];
  isMandatory: Scalars["Boolean"]["input"];
}>;

export type AddFinancialMutation = {
  __typename?: "Mutation";
  addFinancial: { __typename?: "PropertyFinancials"; id: string };
};

export type UpdateFinancialMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  propertyId: Scalars["String"]["input"];
  projectedAnnualRoIPercentage: Scalars["Int"]["input"];
  projectedAnnualRoIValue: Scalars["BigInt"]["input"];
  year2ProjectedAnnualRoIPercentage: Scalars["Int"]["input"];
  year2ProjectedAnnualRoIValue: Scalars["BigInt"]["input"];
  year3ProjectedAnnualRoIPercentage: Scalars["Int"]["input"];
  year3ProjectedAnnualRoIValue: Scalars["BigInt"]["input"];
  annualRent: Scalars["BigInt"]["input"];
  effectiveDate: Scalars["String"]["input"];
  isMandatory: Scalars["Boolean"]["input"];
}>;

export type UpdateFinancialMutation = {
  __typename?: "Mutation";
  updateFinancial: { __typename?: "PropertyFinancials"; id: string };
};

export type DeleteFinancialMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteFinancialMutation = {
  __typename?: "Mutation";
  deleteFinancial: boolean;
};

export type UpdateInvestorMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  status?: InputMaybe<UserStatus>;
}>;

export type UpdateInvestorMutation = {
  __typename?: "Mutation";
  updateInvestor: { __typename?: "User"; id: string };
};

export type UpdateBalanceMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  balance: Scalars["Int"]["input"];
}>;

export type UpdateBalanceMutation = {
  __typename?: "Mutation";
  updateBalance: { __typename?: "Investor"; id: string; balance: any };
};

export type SendEmailToLeadMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  subject: Scalars["String"]["input"];
  body: Scalars["String"]["input"];
}>;

export type SendEmailToLeadMutation = {
  __typename?: "Mutation";
  sendEmailToLead: boolean;
};

export type SoftDeleteLeadMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type SoftDeleteLeadMutation = {
  __typename?: "Mutation";
  softDeleteLead?: Array<{ __typename?: "InvestorLead"; email: string }> | null;
};

export type SaveFcmTokenMutationVariables = Exact<{
  fcmToken: Scalars["String"]["input"];
  turnNotificationsOn: Scalars["Boolean"]["input"];
  deviceId: Scalars["String"]["input"];
}>;

export type SaveFcmTokenMutation = {
  __typename?: "Mutation";
  saveFcmToken: {
    __typename?: "SaveFcmTokenResponse";
    success: boolean;
    message: string;
  };
};

export type UpdateHideNotificationBarMutationVariables = Exact<{
  hideNotificationBar: Scalars["Boolean"]["input"];
  deviceId: Scalars["String"]["input"];
}>;

export type UpdateHideNotificationBarMutation = {
  __typename?: "Mutation";
  updateHideNotificationBar: {
    __typename?: "SaveFcmTokenResponse";
    success: boolean;
    message: string;
  };
};

export type AddPasswordResetMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type AddPasswordResetMutation = {
  __typename?: "Mutation";
  addPasswordReset: boolean;
};

export type ResetPasswordMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  code: Scalars["String"]["input"];
  newPassword: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword: {
    __typename?: "ResetPasswordResponse";
    success: boolean;
    message: string;
  };
};

export type AddPropertyTypeMutationVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type AddPropertyTypeMutation = {
  __typename?: "Mutation";
  addPropertyType: { __typename?: "PropertyType"; id: string; name: string };
};

export type UpdatePropertyTypeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}>;

export type UpdatePropertyTypeMutation = {
  __typename?: "Mutation";
  updatePropertyType: { __typename?: "PropertyType"; id: string; name: string };
};

export type DeletePropertyTypeMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeletePropertyTypeMutation = {
  __typename?: "Mutation";
  deletePropertyType: { __typename?: "PropertyType"; id: string };
};

export type AddPropertyMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  excerpt: Scalars["String"]["input"];
  address: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
  bed: Scalars["Int"]["input"];
  bath: Scalars["Int"]["input"];
  sqFootage: Scalars["Int"]["input"];
  agreementPrice: Scalars["BigInt"]["input"];
  transactionFee: Scalars["BigInt"]["input"];
  serviceCharges: Scalars["BigInt"]["input"];
  fundingDeadlineDate: Scalars["DateTime"]["input"];
  status: PropertyStatus;
  type: Scalars["String"]["input"];
  minimumInvestment: Scalars["Int"]["input"];
}>;

export type AddPropertyMutation = {
  __typename?: "Mutation";
  addProperty: { __typename?: "Property"; id: string };
};

export type UpdatePropertyMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  excerpt: Scalars["String"]["input"];
  address: Scalars["String"]["input"];
  city: Scalars["String"]["input"];
  lat: Scalars["Float"]["input"];
  lng: Scalars["Float"]["input"];
  bed: Scalars["Int"]["input"];
  bath: Scalars["Int"]["input"];
  sqFootage: Scalars["Int"]["input"];
  agreementPrice: Scalars["BigInt"]["input"];
  transactionFee: Scalars["BigInt"]["input"];
  serviceCharges: Scalars["BigInt"]["input"];
  fundingDeadlineDate: Scalars["DateTime"]["input"];
  status: PropertyStatus;
  type: Scalars["String"]["input"];
  minimumInvestment: Scalars["Int"]["input"];
  manualFundedDays?: InputMaybe<Scalars["BigInt"]["input"]>;
  manualFunded?: InputMaybe<Scalars["BigInt"]["input"]>;
}>;

export type UpdatePropertyMutation = {
  __typename?: "Mutation";
  updateProperty: { __typename?: "Property"; id: string };
};

export type DeletePropertyImageMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  imageId: Scalars["String"]["input"];
}>;

export type DeletePropertyImageMutation = {
  __typename?: "Mutation";
  deletePropertyImage: boolean;
};

export type DeletePropertyDocumentMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  documentId: Scalars["String"]["input"];
}>;

export type DeletePropertyDocumentMutation = {
  __typename?: "Mutation";
  deletePropertyDocument: boolean;
};

export type DeletePropertyVideoMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  videoId: Scalars["String"]["input"];
}>;

export type DeletePropertyVideoMutation = {
  __typename?: "Mutation";
  deletePropertyVideo: boolean;
};

export type SoftDeletePropertyMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type SoftDeletePropertyMutation = {
  __typename?: "Mutation";
  softDeleteProperty: {
    __typename?: "Property";
    id: string;
    title: string;
    deletedAt?: any | null;
  };
};

export type UpdatePropertyDocumentsOrderMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  documents: Array<DocumentOrder> | DocumentOrder;
}>;

export type UpdatePropertyDocumentsOrderMutation = {
  __typename?: "Mutation";
  updatePropertyDocumentsOrder: { __typename?: "Property"; id: string };
};

export type UpdatePropertyImagesOrderMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  images: Array<ImageOrder> | ImageOrder;
}>;

export type UpdatePropertyImagesOrderMutation = {
  __typename?: "Mutation";
  updatePropertyImagesOrder: { __typename?: "Property"; id: string };
};

export type UpdatePropertyVideosOrderMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  videos: Array<VideoOrder> | VideoOrder;
}>;

export type UpdatePropertyVideosOrderMutation = {
  __typename?: "Mutation";
  updatePropertyVideosOrder: { __typename?: "Property"; id: string };
};

export type UpdatePropertiesOrderMutationVariables = Exact<{
  properties:
    | Array<PropertiesOrderUpdateRequest>
    | PropertiesOrderUpdateRequest;
}>;

export type UpdatePropertiesOrderMutation = {
  __typename?: "Mutation";
  updatePropertiesOrder: Array<{ __typename?: "Property"; id: string }>;
};

export type CreateSourceMutationVariables = Exact<{
  createSource: CreateSourceInput;
}>;

export type CreateSourceMutation = {
  __typename?: "Mutation";
  createSource: {
    __typename?: "Source";
    id: string;
    name: string;
    email: string;
  };
};

export type CreateCouponMutationVariables = Exact<{
  data: CreateCouponInput;
}>;

export type CreateCouponMutation = {
  __typename?: "Mutation";
  createCoupon: { __typename?: "Coupon"; id: string; code: string };
};

export type AddRentMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  rent: Scalars["BigInt"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  isPaid: Scalars["Boolean"]["input"];
}>;

export type AddRentMutation = {
  __typename?: "Mutation";
  addRent: { __typename?: "PropertyRent"; id: string };
};

export type UpdateRentMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  propertyId: Scalars["String"]["input"];
  rent: Scalars["BigInt"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  isPaid: Scalars["Boolean"]["input"];
}>;

export type UpdateRentMutation = {
  __typename?: "Mutation";
  updateRent: { __typename?: "PropertyRent"; id: string };
};

export type DeleteRentMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteRentMutation = {
  __typename?: "Mutation";
  deleteRent: boolean;
};

export type AddRoleMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
  assignments?: InputMaybe<Array<RoleAssignmentScalar> | RoleAssignmentScalar>;
}>;

export type AddRoleMutation = {
  __typename?: "Mutation";
  addRole: { __typename?: "Role"; id: string };
};

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  assignments?: InputMaybe<Array<RoleAssignmentScalar> | RoleAssignmentScalar>;
}>;

export type UpdateRoleMutation = {
  __typename?: "Mutation";
  updateRole: { __typename?: "Role"; id: string };
};

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteRoleMutation = {
  __typename?: "Mutation";
  deleteRole: { __typename?: "Role"; id: string };
};

export type AddTimelineMutationVariables = Exact<{
  propertyId: Scalars["String"]["input"];
  date: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
}>;

export type AddTimelineMutation = {
  __typename?: "Mutation";
  addTimelineEvent: { __typename?: "PropertyTimeline"; id: string };
};

export type UpdateTimelineMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  propertyId: Scalars["String"]["input"];
  date: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
}>;

export type UpdateTimelineMutation = {
  __typename?: "Mutation";
  updateTimelineEvent: { __typename?: "PropertyTimeline"; id: string };
};

export type DeleteTimelineMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteTimelineMutation = {
  __typename?: "Mutation";
  deleteTimelineEvent: boolean;
};

export type DepositFiatMutationVariables = Exact<{
  userId: Scalars["String"]["input"];
  amount: Scalars["Int"]["input"];
  reference?: InputMaybe<Scalars["String"]["input"]>;
  source: Scalars["String"]["input"];
}>;

export type DepositFiatMutation = {
  __typename?: "Mutation";
  depositFiat?: {
    __typename?: "Transaction";
    id: string;
    status: TransactionStatus;
  } | null;
};

export type RefundTransactionMutationVariables = Exact<{
  transactionId: Scalars["String"]["input"];
  reason: Scalars["String"]["input"];
}>;

export type RefundTransactionMutation = {
  __typename?: "Mutation";
  refundTransaction: {
    __typename?: "Transaction";
    id: string;
    status: TransactionStatus;
  };
};

export type SendOtpMutationVariables = Exact<{
  deviceId: Scalars["String"]["input"];
}>;

export type SendOtpMutation = { __typename?: "Mutation"; sendOTP: string };

export type VerifyOtpMutationVariables = Exact<{
  otp: Scalars["String"]["input"];
}>;

export type VerifyOtpMutation = { __typename?: "Mutation"; verifyOTP: boolean };

export type GetAllCampaignsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCampaignsQuery = {
  __typename?: "Query";
  campaigns: Array<{
    __typename?: "Campaign";
    id: string;
    name: string;
    title: string;
    scheduleType: CampaignScheduleType;
    status: CampaignStatus;
    scheduledAt?: any | null;
    isActive: boolean;
    investorStatus: CampaignInvestorStatus;
  }>;
};

export type GetCampaignByIdQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetCampaignByIdQuery = {
  __typename?: "Query";
  campaign: {
    __typename?: "Campaign";
    id: string;
    title: string;
    name: string;
    description: string;
    scheduleType: CampaignScheduleType;
    investorStatus: CampaignInvestorStatus;
    isActive: boolean;
    scheduledAt?: any | null;
    status: CampaignStatus;
    filterStartDate?: any | null;
    filterEndDate?: any | null;
  };
};

export type GetCmsValuesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCmsValuesQuery = {
  __typename?: "Query";
  getCMSValues: Array<{
    __typename?: "CMSValue";
    name: string;
    key: string;
    value: string;
    section: { __typename?: "CMSSection"; name: string };
    page: { __typename?: "CMSPage"; name: string };
    language: { __typename?: "CMSLang"; key: string };
  }>;
};

export type GetCitiesWithPropertiesAndInvestmentsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCitiesWithPropertiesAndInvestmentsQuery = {
  __typename?: "Query";
  cities: Array<{
    __typename?: "City";
    id: string;
    name: string;
    excerpt?: string | null;
    description?: string | null;
    propertyDescription?: string | null;
    location?: any | null;
    properties: Array<{
      __typename?: "Property";
      id: string;
      pledges: Array<{
        __typename?: "PropertyPledge";
        amount: any;
        status: PropertyPledgeStatus;
      }>;
    }>;
  }>;
};

export type EmailExistsQueryVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type EmailExistsQuery = { __typename?: "Query"; userExists: boolean };

export type GetNotificationStatusQueryVariables = Exact<{
  deviceId: Scalars["String"]["input"];
}>;

export type GetNotificationStatusQuery = {
  __typename?: "Query";
  getNotificationStatus: {
    __typename?: "GetNotificationStatusResponse";
    id: string;
    turnNotificationsOn: boolean;
    hideNotificationBar: boolean;
  };
};

export type ListPaymentMethodsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ListPaymentMethodsQuery = {
  __typename?: "Query";
  listPaymentMethods: Array<{
    __typename?: "PaymentMethod";
    id: string;
    type: string;
    card?: {
      __typename?: "Card";
      display_brand: string;
      brand: string;
      country: string;
      exp_month: number;
      exp_year: number;
      last4: string;
    } | null;
    sepa_debit?: {
      __typename?: "SepaDebit";
      bank_code: string;
      branch_code: string;
      country: string;
      last4: string;
    } | null;
  }>;
};

export type GetPropertyPledgeByIdQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetPropertyPledgeByIdQuery = {
  __typename?: "Query";
  getPropertyPledgeById: {
    __typename?: "PledgeByIdResponse";
    total: number;
    items: Array<{
      __typename?: "PropertyPledge";
      amount: any;
      createdAt: any;
      updatedAt: any;
      status: PropertyPledgeStatus;
      property: { __typename?: "Property"; title: string };
    }>;
  };
};

export type GetPropertyQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetPropertyQuery = {
  __typename?: "Query";
  property: {
    __typename?: "Property";
    id: string;
    title: string;
    description: string;
    excerpt: string;
    address: string;
    location?: any | null;
    code: string;
    price: any;
    bed: number;
    bath: number;
    sqFootage: number;
    agreementPrice: any;
    transactionFee: any;
    platformFee: any;
    serviceCharges: any;
    fundingDeadlineDate: any;
    minimumInvestment: number;
    manualFundedDays?: any | null;
    manualFunded?: any | null;
    status: PropertyStatus;
    createdAt: any;
    city: {
      __typename?: "City";
      name: string;
      excerpt?: string | null;
      description?: string | null;
      propertyDescription?: string | null;
    };
    amenities?: Array<{ __typename?: "PropertyAmenity"; title: string }> | null;
    timelineEvents: Array<{
      __typename?: "PropertyTimeline";
      id: string;
      title: string;
      description: string;
      date: any;
    }>;
    financials: Array<{
      __typename?: "PropertyFinancials";
      id: string;
      projectedAnnualRoIPercentage: any;
      projectedAnnualRoIValue: any;
      year2ProjectedAnnualRoIPercentage: any;
      year2ProjectedAnnualRoIValue: any;
      year3ProjectedAnnualRoIPercentage: any;
      year3ProjectedAnnualRoIValue: any;
      annualRent: any;
      effectiveDate: any;
      endDate?: any | null;
      isMandatory?: boolean | null;
    }>;
    images?: Array<{
      __typename?: "PropertyFile";
      id: string;
      ext: string;
      order: number;
    }> | null;
    videos?: Array<{
      __typename?: "PropertyFile";
      id: string;
      ext: string;
      order: number;
    }> | null;
    rents: Array<{
      __typename?: "PropertyRent";
      id: string;
      date?: any | null;
      rent: any;
      isPaid: boolean;
      isDisbursed: boolean;
    }>;
    documents?: Array<{
      __typename?: "PropertyFile";
      id: string;
      ext: string;
      filename: string;
    }> | null;
    type?: { __typename?: "PropertyType"; name: string } | null;
    pledges: Array<{
      __typename?: "PropertyPledge";
      id: string;
      status: PropertyPledgeStatus;
      amount: any;
    }>;
  };
};

export type GetPropertiesQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WhereInput>;
}>;

export type GetPropertiesQuery = {
  __typename?: "Query";
  properties: Array<{
    __typename?: "Property";
    id: string;
    title: string;
    code: string;
    price: any;
    minimumInvestment: number;
    status: PropertyStatus;
    order: number;
    agreementPrice: any;
    isRented: boolean;
    pledges: Array<{
      __typename?: "PropertyPledge";
      amount: any;
      status: PropertyPledgeStatus;
    }>;
    city: { __typename?: "City"; name: string };
  }>;
};

export type GetTransactionByIdQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetTransactionByIdQuery = {
  __typename?: "Query";
  getTransactionById: {
    __typename?: "TransactionByIdResponse";
    total: number;
    items: Array<{
      __typename?: "Transaction";
      type: TransactionType;
      amount: any;
      code: string;
      createdAt: any;
      updatedAt: any;
      finalisedAt?: any | null;
      status: TransactionStatus;
      property?: { __typename?: "Property"; title: string } | null;
    }>;
  };
};

export type DepositsQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["String"]["input"];
  endDate: Scalars["String"]["input"];
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type DepositsQuery = {
  __typename?: "Query";
  deposits: {
    __typename?: "PaginatedDespositResponse";
    total: number;
    sumTotal: number;
    items: Array<{
      __typename?: "Transaction";
      id: string;
      code: string;
      amount: any;
      source: TransactionSource;
      ccFee?: any | null;
      stripeFee?: any | null;
      initiatedBy: InitiatorType;
      status: TransactionStatus;
      updatedAt: any;
      finalisedAt?: any | null;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        status: UserStatus;
        investorProfile?: {
          __typename?: "Investor";
          id: string;
          balance: any;
        } | null;
      };
    }>;
  };
};

export type InvestmentsQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["String"]["input"];
  endDate: Scalars["String"]["input"];
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InvestmentsQuery = {
  __typename?: "Query";
  investments: {
    __typename?: "PaginatedDespositResponse";
    total: number;
    sumTotal: number;
    items: Array<{
      __typename?: "Transaction";
      id: string;
      code: string;
      amount: any;
      status: TransactionStatus;
      updatedAt: any;
      finalisedAt?: any | null;
      user: {
        __typename?: "User";
        id: string;
        firstName: string;
        lastName: string;
        email: string;
        phone?: string | null;
        status: UserStatus;
        investorProfile?: {
          __typename?: "Investor";
          id: string;
          balance: any;
        } | null;
      };
      property?: { __typename?: "Property"; id: string; title: string } | null;
    }>;
  };
};

export type GetInterestedInvestorsQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["String"]["input"];
  endDate: Scalars["String"]["input"];
  hubSpotLifecycleStage?: InputMaybe<HubSpotContactLifeCycleStatus>;
}>;

export type GetInterestedInvestorsQuery = {
  __typename?: "Query";
  getInterestedInvestors: {
    __typename?: "InvestorLeadListResponse";
    total: number;
    items: Array<{
      __typename?: "InvestorLead";
      id: string;
      email: string;
      fullName: string;
      phoneNumber: string;
      createdAt: any;
      utm_id?: string | null;
      utm_campaign?: string | null;
      utm_medium?: string | null;
      utm_source?: string | null;
      utm_content?: string | null;
      utm_term?: string | null;
      intercomLeadConversationId?: string | null;
      hubSpotLifecycleStage?: HubSpotContactLifeCycleStatus | null;
    }>;
  };
};

export type GetUnPaginatedInterestedInvestorsQueryVariables = Exact<{
  startDate: Scalars["String"]["input"];
  endDate: Scalars["String"]["input"];
}>;

export type GetUnPaginatedInterestedInvestorsQuery = {
  __typename?: "Query";
  getUnPaginatedInterestedInvestors: {
    __typename?: "InvestorLeadListResponse";
    total: number;
    items: Array<{
      __typename?: "InvestorLead";
      id: string;
      email: string;
      fullName: string;
      phoneNumber: string;
      createdAt: any;
      utm_id?: string | null;
      utm_campaign?: string | null;
      utm_medium?: string | null;
      utm_source?: string | null;
      utm_content?: string | null;
      utm_term?: string | null;
      intercomLeadConversationId?: string | null;
    }>;
  };
};

export type GetPropertyTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetPropertyTypesQuery = {
  __typename?: "Query";
  propertyTypes: Array<{
    __typename?: "PropertyType";
    id: string;
    name: string;
  }>;
};

export type GetAllSourcesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSourcesQuery = {
  __typename?: "Query";
  getAllSources: Array<{
    __typename?: "Source";
    id: string;
    name: string;
    email: string;
  }>;
};

export type GetAllCouponsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCouponsQuery = {
  __typename?: "Query";
  getAllCoupons: Array<{
    __typename?: "Coupon";
    id: string;
    code: string;
    incentive: CouponIncentive;
  }>;
};

export type GetAllSourcesWithTotalsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllSourcesWithTotalsQuery = {
  __typename?: "Query";
  getAllSourcesWithTotals: Array<{
    __typename?: "SourceWithTotals";
    totalCoupons: number;
    totalInvestors: number;
    source: { __typename?: "Source"; id: string; name: string; email: string };
  }>;
};

export type GetSourceByIdQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetSourceByIdQuery = {
  __typename?: "Query";
  getSourceById?: {
    __typename?: "Source";
    id: string;
    name: string;
    email: string;
  } | null;
};

export type GetCouponsBySourceQueryVariables = Exact<{
  sourceId: Scalars["String"]["input"];
}>;

export type GetCouponsBySourceQuery = {
  __typename?: "Query";
  getCouponsBySourceId: Array<{
    __typename?: "CouponWithInvestorCount";
    totalInvestors: number;
    coupon: {
      __typename?: "Coupon";
      id: string;
      code: string;
      incentive: CouponIncentive;
    };
  }>;
};

export type GetUserByReferralQueryVariables = Exact<{
  sourceId: Scalars["String"]["input"];
}>;

export type GetUserByReferralQuery = {
  __typename?: "Query";
  getUserByReferralAndCoupon: Array<{
    __typename?: "UserWithCoupon";
    coupon: string;
    user: {
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      status: UserStatus;
      createdAt: any;
      investorProfile?: {
        __typename?: "Investor";
        balance: any;
        hubSpotDealStatus?: HubSpotDealStatus | null;
      } | null;
    };
  }>;
};

export type GetUsersByCouponQueryVariables = Exact<{
  couponId: Scalars["String"]["input"];
}>;

export type GetUsersByCouponQuery = {
  __typename?: "Query";
  getUsersByCoupon: {
    __typename?: "UserAndCoupon";
    coupon: string;
    users: Array<{
      __typename?: "User";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      status: UserStatus;
      createdAt: any;
      investorProfile?: {
        __typename?: "Investor";
        balance: any;
        hubSpotDealStatus?: HubSpotDealStatus | null;
      } | null;
    }>;
  };
};

export type FindRolesQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WhereInput>;
}>;

export type FindRolesQuery = {
  __typename?: "Query";
  roles: Array<{
    __typename?: "Role";
    id: string;
    name: string;
    description: string;
    assignments: Array<{
      __typename?: "RoleAssignment";
      id: string;
      module: RoleModule;
      action: RoleAction;
    }>;
    admins: Array<{ __typename?: "Admin"; id: string }>;
  }>;
};

export type GetRoleQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetRoleQuery = {
  __typename?: "Query";
  roleById: {
    __typename?: "Role";
    id: string;
    name: string;
    description: string;
    assignments: Array<{
      __typename?: "RoleAssignment";
      id: string;
      module: RoleModule;
      action: RoleAction;
    }>;
  };
};

export type FindPaginatedUsersQueryVariables = Exact<{
  take?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  startDate: Scalars["String"]["input"];
  endDate: Scalars["String"]["input"];
  where?: InputMaybe<WhereInput>;
  searchTerm?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FindPaginatedUsersQuery = {
  __typename?: "Query";
  users: {
    __typename?: "PaginatedUserResponse";
    total: number;
    items: Array<{
      __typename?: "UserTotals";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      createdAt: any;
      status: UserStatus;
      totalDeposits?: number | null;
      totalInvestments?: number | null;
      investorProfile?: {
        __typename?: "Investor";
        balance: any;
        hubSpotDealStatus?: HubSpotDealStatus | null;
      } | null;
      adminProfile?: {
        __typename?: "Admin";
        id: string;
        role: { __typename?: "Role"; id: string; name: string };
      } | null;
    }>;
  };
};

export type FindUsersQueryVariables = Exact<{
  startDate: Scalars["String"]["input"];
  endDate: Scalars["String"]["input"];
  where?: InputMaybe<WhereInput>;
  sourceId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FindUsersQuery = {
  __typename?: "Query";
  unPaginatedUsers: {
    __typename?: "PaginatedUserResponse";
    total: number;
    items: Array<{
      __typename?: "UserTotals";
      id: string;
      firstName: string;
      lastName: string;
      email: string;
      phone?: string | null;
      createdAt: any;
      status: UserStatus;
      totalDeposits?: number | null;
      totalInvestments?: number | null;
      investorProfile?: { __typename?: "Investor"; balance: any } | null;
      adminProfile?: {
        __typename?: "Admin";
        id: string;
        role: { __typename?: "Role"; id: string; name: string };
      } | null;
    }>;
  };
};

export type GetUserByIdQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetUserByIdQuery = {
  __typename?: "Query";
  userById: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    status: UserStatus;
    investorProfile?: {
      __typename?: "Investor";
      balance: any;
      solanaWalletAddress?: string | null;
      rewardBalance?: number | null;
      coupon?: { __typename?: "Coupon"; code: string } | null;
    } | null;
  };
};

export type GetUserQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetUserQuery = {
  __typename?: "Query";
  userById: {
    __typename?: "User";
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phone?: string | null;
    status: UserStatus;
    adminProfile?: {
      __typename?: "Admin";
      id: string;
      role: { __typename?: "Role"; id: string; name: string };
    } | null;
  };
};

export type GetMyRoleQueryVariables = Exact<{ [key: string]: never }>;

export type GetMyRoleQuery = {
  __typename?: "Query";
  myRole: {
    __typename?: "Role";
    name: string;
    assignments: Array<{
      __typename?: "RoleAssignment";
      module: RoleModule;
      action: RoleAction;
    }>;
  };
};

export type VerifyDeviceQueryVariables = Exact<{
  email: Scalars["String"]["input"];
  deviceId: Scalars["String"]["input"];
}>;

export type VerifyDeviceQuery = { __typename?: "Query"; verifyDevice: boolean };

export const UpdateAdminDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateAdmin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "UserStatus" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roleId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAdmin" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "firstName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "firstName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lastName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lastName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "password" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "password" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "roleId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "roleId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateAdminMutation, UpdateAdminMutationVariables>;
export const AddUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastName" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "UserStatus" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "roleId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addAdmin" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "firstName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "firstName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lastName" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lastName" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "password" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "password" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "phone" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phone" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "roleId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "roleId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddUserMutation, AddUserMutationVariables>;
export const DeleteAdminDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteAdmin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteAdmin" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteAdminMutation, DeleteAdminMutationVariables>;
export const SendUnverifiedReminderEmailDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendUnverifiedReminderEmail" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendUnverifiedReminderEmail" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendUnverifiedReminderEmailMutation,
  SendUnverifiedReminderEmailMutationVariables
>;
export const UpdateAmenitiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateAmenities" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "amenities" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateAmenities" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "amenities" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "amenities" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateAmenitiesMutation,
  UpdateAmenitiesMutationVariables
>;
export const LoginDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Login" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "password" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "login" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "password" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "password" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "accessToken" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const AddCampaignDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "AddCampaign" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "title" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "scheduleType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CampaignScheduleType" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "scheduleDate" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "investorStatus" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CampaignInvestorStatus" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "signupStart" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "signupEnd" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addCampaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "title" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scheduleType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "scheduleType" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "scheduleDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "scheduleDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "investorStatus" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "investorStatus" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "signupStart" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "signupStart" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "signupEnd" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "signupEnd" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddCampaignMutation, AddCampaignMutationVariables>;
export const MarkCampaignAsActiveDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "MarkCampaignAsActive" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "markAsActive" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  MarkCampaignAsActiveMutation,
  MarkCampaignAsActiveMutationVariables
>;
export const UpdateCityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateCity" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "updateCityInput" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateCityInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateCity" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "updateCityInput" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "updateCityInput" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "propertyDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateCityMutation, UpdateCityMutationVariables>;
export const SaveOrUpdateCmsValuesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "saveOrUpdateCMSValues" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "CMSValueInput" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveOrUpdateCMSValues" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveOrUpdateCmsValuesMutation,
  SaveOrUpdateCmsValuesMutationVariables
>;
export const CheckAndSaveDeviceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "checkAndSaveDevice" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "osVersion" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceType" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "model" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkAndSaveDevice" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "checkAndSaveDeviceInput" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deviceId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "osVersion" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "osVersion" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "deviceType" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "deviceType" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "model" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "model" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CheckAndSaveDeviceMutation,
  CheckAndSaveDeviceMutationVariables
>;
export const AddFinancialDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addFinancial" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectedAnnualRoIPercentage" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectedAnnualRoIValue" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "year2ProjectedAnnualRoIPercentage" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "year2ProjectedAnnualRoIValue" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "year3ProjectedAnnualRoIPercentage" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "year3ProjectedAnnualRoIValue" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "annualRent" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "effectiveDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isMandatory" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addFinancial" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "projectedAnnualRoIPercentage",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIPercentage",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "projectedAnnualRoIValue" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIValue",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "year2ProjectedAnnualRoIPercentage",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "year2ProjectedAnnualRoIPercentage",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "year2ProjectedAnnualRoIValue",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "year2ProjectedAnnualRoIValue",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "year3ProjectedAnnualRoIPercentage",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIPercentage",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "year3ProjectedAnnualRoIValue",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIValue",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "annualRent" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "annualRent" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "effectiveDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "effectiveDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "isMandatory" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isMandatory" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddFinancialMutation,
  AddFinancialMutationVariables
>;
export const UpdateFinancialDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateFinancial" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectedAnnualRoIPercentage" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "projectedAnnualRoIValue" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "year2ProjectedAnnualRoIPercentage" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "year2ProjectedAnnualRoIValue" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "year3ProjectedAnnualRoIPercentage" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "year3ProjectedAnnualRoIValue" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "annualRent" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "effectiveDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isMandatory" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateFinancial" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "projectedAnnualRoIPercentage",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIPercentage",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "projectedAnnualRoIValue" },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIValue",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "year2ProjectedAnnualRoIPercentage",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "year2ProjectedAnnualRoIPercentage",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "year2ProjectedAnnualRoIValue",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "year2ProjectedAnnualRoIValue",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "year3ProjectedAnnualRoIPercentage",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIPercentage",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: {
                        kind: "Name",
                        value: "year3ProjectedAnnualRoIValue",
                      },
                      value: {
                        kind: "Variable",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIValue",
                        },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "annualRent" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "annualRent" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "effectiveDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "effectiveDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "isMandatory" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isMandatory" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateFinancialMutation,
  UpdateFinancialMutationVariables
>;
export const DeleteFinancialDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteFinancial" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteFinancial" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteFinancialMutation,
  DeleteFinancialMutationVariables
>;
export const UpdateInvestorDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateInvestor" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "UserStatus" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateInvestor" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateInvestorMutation,
  UpdateInvestorMutationVariables
>;
export const UpdateBalanceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateBalance" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "balance" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateBalance" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "balance" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "balance" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "balance" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateBalanceMutation,
  UpdateBalanceMutationVariables
>;
export const SendEmailToLeadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendEmailToLead" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "subject" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "body" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendEmailToLead" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "subject" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "subject" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "body" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "body" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SendEmailToLeadMutation,
  SendEmailToLeadMutationVariables
>;
export const SoftDeleteLeadDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "softDeleteLead" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "softDeleteLead" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SoftDeleteLeadMutation,
  SoftDeleteLeadMutationVariables
>;
export const SaveFcmTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SaveFcmToken" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fcmToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "turnNotificationsOn" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "saveFcmToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "fcmToken" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "fcmToken" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "turnNotificationsOn" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "turnNotificationsOn" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "deviceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deviceId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SaveFcmTokenMutation,
  SaveFcmTokenMutationVariables
>;
export const UpdateHideNotificationBarDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdateHideNotificationBar" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hideNotificationBar" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateHideNotificationBar" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "hideNotificationBar" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hideNotificationBar" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "deviceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deviceId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateHideNotificationBarMutation,
  UpdateHideNotificationBarMutationVariables
>;
export const AddPasswordResetDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addPasswordReset" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addPasswordReset" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "email" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "email" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddPasswordResetMutation,
  AddPasswordResetMutationVariables
>;
export const ResetPasswordDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "resetPassword" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "newPassword" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "resetPassword" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "code" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "code" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "newPassword" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "newPassword" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "success" } },
                { kind: "Field", name: { kind: "Name", value: "message" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const AddPropertyTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addPropertyType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addPropertyType" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  AddPropertyTypeMutation,
  AddPropertyTypeMutationVariables
>;
export const UpdatePropertyTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updatePropertyType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePropertyType" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "name" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "name" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePropertyTypeMutation,
  UpdatePropertyTypeMutationVariables
>;
export const DeletePropertyTypeDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deletePropertyType" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deletePropertyType" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePropertyTypeMutation,
  DeletePropertyTypeMutationVariables
>;
export const AddPropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "title" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excerpt" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lat" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lng" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "bed" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "bath" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sqFootage" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "agreementPrice" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "transactionFee" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "serviceCharges" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fundingDeadlineDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PropertyStatus" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "minimumInvestment" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addProperty" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "title" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "excerpt" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "excerpt" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "address" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "address" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "city" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "city" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lat" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lat" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lng" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lng" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "bed" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bed" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "bath" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bath" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sqFootage" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sqFootage" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "agreementPrice" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "agreementPrice" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transactionFee" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "transactionFee" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "serviceCharges" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "serviceCharges" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fundingDeadlineDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fundingDeadlineDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "type" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "minimumInvestment" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "minimumInvestment" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddPropertyMutation, AddPropertyMutationVariables>;
export const UpdatePropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "title" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "excerpt" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "address" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "city" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lat" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "lng" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Float" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "bed" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "bath" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sqFootage" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "agreementPrice" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "transactionFee" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "serviceCharges" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "fundingDeadlineDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DateTime" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "PropertyStatus" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "minimumInvestment" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "manualFundedDays" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "BigInt" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "manualFunded" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "BigInt" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateProperty" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "title" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "excerpt" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "excerpt" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "address" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "address" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "city" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "city" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lat" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lat" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lng" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lng" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "bed" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bed" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "bath" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "bath" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "sqFootage" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "sqFootage" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "agreementPrice" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "agreementPrice" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "transactionFee" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "transactionFee" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "serviceCharges" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "serviceCharges" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "fundingDeadlineDate" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "fundingDeadlineDate" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "status" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "status" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "type" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "type" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "minimumInvestment" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "minimumInvestment" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "manualFunded" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "manualFunded" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "manualFundedDays" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "manualFundedDays" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePropertyMutation,
  UpdatePropertyMutationVariables
>;
export const DeletePropertyImageDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deletePropertyImage" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "imageId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deletePropertyImage" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "imageId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "imageId" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePropertyImageMutation,
  DeletePropertyImageMutationVariables
>;
export const DeletePropertyDocumentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deletePropertyDocument" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "documentId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deletePropertyDocument" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "documentId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "documentId" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePropertyDocumentMutation,
  DeletePropertyDocumentMutationVariables
>;
export const DeletePropertyVideoDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deletePropertyVideo" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "videoId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deletePropertyVideo" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "videoId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "videoId" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeletePropertyVideoMutation,
  DeletePropertyVideoMutationVariables
>;
export const SoftDeletePropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "SoftDeleteProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "softDeleteProperty" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "deletedAt" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  SoftDeletePropertyMutation,
  SoftDeletePropertyMutationVariables
>;
export const UpdatePropertyDocumentsOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updatePropertyDocumentsOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "documents" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "DocumentOrder" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePropertyDocumentsOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "documents" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "documents" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePropertyDocumentsOrderMutation,
  UpdatePropertyDocumentsOrderMutationVariables
>;
export const UpdatePropertyImagesOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updatePropertyImagesOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "images" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "ImageOrder" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePropertyImagesOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "images" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "images" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePropertyImagesOrderMutation,
  UpdatePropertyImagesOrderMutationVariables
>;
export const UpdatePropertyVideosOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updatePropertyVideosOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "videos" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "VideoOrder" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePropertyVideosOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "videos" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "videos" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePropertyVideosOrderMutation,
  UpdatePropertyVideosOrderMutationVariables
>;
export const UpdatePropertiesOrderDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updatePropertiesOrder" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "properties" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "PropertiesOrderUpdateRequest" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updatePropertiesOrder" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "properties" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdatePropertiesOrderMutation,
  UpdatePropertiesOrderMutationVariables
>;
export const CreateSourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateSource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "createSource" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateSourceInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createSource" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "createSource" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "createSource" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateSourceMutation,
  CreateSourceMutationVariables
>;
export const CreateCouponDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "CreateCoupon" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "data" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateCouponInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "createCoupon" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "data" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  CreateCouponMutation,
  CreateCouponMutationVariables
>;
export const AddRentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addRent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "rent" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "date" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isPaid" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addRent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "rent" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "rent" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "date" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "date" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "isPaid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isPaid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddRentMutation, AddRentMutationVariables>;
export const UpdateRentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateRent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "rent" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "BigInt" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "date" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isPaid" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateRent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "rent" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "rent" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "date" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "date" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "isPaid" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "isPaid" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRentMutation, UpdateRentMutationVariables>;
export const DeleteRentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteRent" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteRent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRentMutation, DeleteRentMutationVariables>;
export const AddRoleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addRole" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "assignments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "RoleAssignmentScalar" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addRole" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "assignments" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "assignments" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddRoleMutation, AddRoleMutationVariables>;
export const UpdateRoleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateRole" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "assignments" },
          },
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "RoleAssignmentScalar" },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateRole" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "name" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "name" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "assignments" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "assignments" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteRole" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteRole" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const AddTimelineDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "addTimeline" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "date" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "title" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "addTimelineEvent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "date" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "date" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "title" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<AddTimelineMutation, AddTimelineMutationVariables>;
export const UpdateTimelineDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateTimeline" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "propertyId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "date" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "title" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "description" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateTimelineEvent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "propertyId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "propertyId" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "date" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "date" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "title" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "title" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "description" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "description" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  UpdateTimelineMutation,
  UpdateTimelineMutationVariables
>;
export const DeleteTimelineDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "deleteTimeline" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteTimelineEvent" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "data" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DeleteTimelineMutation,
  DeleteTimelineMutationVariables
>;
export const DepositFiatDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "depositFiat" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "amount" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reference" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "source" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "depositFiat" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "userId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "userId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "amount" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "amount" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "reference" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reference" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "source" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "source" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DepositFiatMutation, DepositFiatMutationVariables>;
export const RefundTransactionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "refundTransaction" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "transactionId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reason" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "refundTransaction" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "transactionId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "transactionId" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "reason" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reason" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  RefundTransactionMutation,
  RefundTransactionMutationVariables
>;
export const SendOtpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "sendOTP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "sendOTP" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "deviceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deviceId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<SendOtpMutation, SendOtpMutationVariables>;
export const VerifyOtpDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "verifyOTP" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "otp" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyOTP" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "otp" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "otp" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const GetAllCampaignsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllCampaigns" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "campaigns" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduleType" },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "scheduledAt" } },
                { kind: "Field", name: { kind: "Name", value: "isActive" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "investorStatus" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllCampaignsQuery,
  GetAllCampaignsQueryVariables
>;
export const GetCampaignByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCampaignById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "campaign" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "scheduleType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "investorStatus" },
                },
                { kind: "Field", name: { kind: "Name", value: "isActive" } },
                { kind: "Field", name: { kind: "Name", value: "scheduledAt" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterStartDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "filterEndDate" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCampaignByIdQuery,
  GetCampaignByIdQueryVariables
>;
export const GetCmsValuesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCMSValues" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getCMSValues" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "key" } },
                { kind: "Field", name: { kind: "Name", value: "value" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "section" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "page" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "language" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "key" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetCmsValuesQuery, GetCmsValuesQueryVariables>;
export const GetCitiesWithPropertiesAndInvestmentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCitiesWithPropertiesAndInvestments" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "cities" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "propertyDescription" },
                },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "properties" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pledges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "amount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCitiesWithPropertiesAndInvestmentsQuery,
  GetCitiesWithPropertiesAndInvestmentsQueryVariables
>;
export const EmailExistsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "emailExists" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userExists" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<EmailExistsQuery, EmailExistsQueryVariables>;
export const GetNotificationStatusDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetNotificationStatus" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getNotificationStatus" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "deviceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deviceId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "turnNotificationsOn" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "hideNotificationBar" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetNotificationStatusQuery,
  GetNotificationStatusQueryVariables
>;
export const ListPaymentMethodsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "listPaymentMethods" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "listPaymentMethods" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "card" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "display_brand" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "brand" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "exp_month" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "exp_year" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "last4" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "sepa_debit" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "bank_code" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "branch_code" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "country" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "last4" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "type" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ListPaymentMethodsQuery,
  ListPaymentMethodsQueryVariables
>;
export const GetPropertyPledgeByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPropertyPledgeById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getPropertyPledgeById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "take" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "take" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "skip" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "skip" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "property" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyPledgeByIdQuery,
  GetPropertyPledgeByIdQueryVariables
>;
export const GetPropertyDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProperty" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "property" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "excerpt" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                { kind: "Field", name: { kind: "Name", value: "location" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                { kind: "Field", name: { kind: "Name", value: "bed" } },
                { kind: "Field", name: { kind: "Name", value: "bath" } },
                { kind: "Field", name: { kind: "Name", value: "sqFootage" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "agreementPrice" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "transactionFee" },
                },
                { kind: "Field", name: { kind: "Name", value: "platformFee" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "serviceCharges" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "fundingDeadlineDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minimumInvestment" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "manualFundedDays" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "manualFunded" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "city" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "excerpt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "propertyDescription" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "amenities" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "timelineEvents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "title" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "date" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "financials" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "projectedAnnualRoIValue",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "year2ProjectedAnnualRoIPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "year2ProjectedAnnualRoIValue",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIPercentage",
                        },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "year3ProjectedAnnualRoIValue",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "annualRent" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "effectiveDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "endDate" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isMandatory" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "images" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "ext" } },
                      { kind: "Field", name: { kind: "Name", value: "order" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "videos" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "ext" } },
                      { kind: "Field", name: { kind: "Name", value: "order" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "date" } },
                      { kind: "Field", name: { kind: "Name", value: "rent" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isPaid" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isDisbursed" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "documents" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "ext" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "filename" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "type" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pledges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertyQuery, GetPropertyQueryVariables>;
export const GetPropertiesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getProperties" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WhereInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "properties" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationOptions" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "take" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "take" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "skip" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "skip" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "price" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "minimumInvestment" },
                },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                { kind: "Field", name: { kind: "Name", value: "order" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "agreementPrice" },
                },
                { kind: "Field", name: { kind: "Name", value: "isRented" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "pledges" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "city" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetPropertiesQuery, GetPropertiesQueryVariables>;
export const GetTransactionByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getTransactionById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getTransactionById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "take" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "take" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "skip" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "skip" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "finalisedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "property" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetTransactionByIdQuery,
  GetTransactionByIdQueryVariables
>;
export const DepositsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "deposits" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchTerm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deposits" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationOptions" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "take" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "take" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "skip" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "skip" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchTerm" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchTerm" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "ccFee" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "stripeFee" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "initiatedBy" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "investorProfile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "balance" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "finalisedAt" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "sumTotal" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<DepositsQuery, DepositsQueryVariables>;
export const InvestmentsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "investments" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchTerm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "investments" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationOptions" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "take" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "take" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "skip" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "skip" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchTerm" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchTerm" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "amount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "firstName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "phone" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "investorProfile" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "balance" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "property" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "title" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "finalisedAt" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "sumTotal" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<InvestmentsQuery, InvestmentsQueryVariables>;
export const GetInterestedInvestorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getInterestedInvestors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "hubSpotLifecycleStage" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "HubSpotContactLifeCycleStatus" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getInterestedInvestors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationOptions" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "take" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "take" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "skip" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "skip" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "hubSpotLifecycleStage" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "hubSpotLifecycleStage" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_campaign" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_medium" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_source" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_content" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_term" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "intercomLeadConversationId",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "hubSpotLifecycleStage" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetInterestedInvestorsQuery,
  GetInterestedInvestorsQueryVariables
>;
export const GetUnPaginatedInterestedInvestorsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUnPaginatedInterestedInvestors" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUnPaginatedInterestedInvestors" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fullName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "phoneNumber" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_id" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_campaign" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_medium" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_source" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_content" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "utm_term" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "intercomLeadConversationId",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUnPaginatedInterestedInvestorsQuery,
  GetUnPaginatedInterestedInvestorsQueryVariables
>;
export const GetPropertyTypesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getPropertyTypes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "propertyTypes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetPropertyTypesQuery,
  GetPropertyTypesQueryVariables
>;
export const GetAllSourcesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllSources" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllSources" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllSourcesQuery, GetAllSourcesQueryVariables>;
export const GetAllCouponsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllCoupons" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllCoupons" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "code" } },
                { kind: "Field", name: { kind: "Name", value: "incentive" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetAllCouponsQuery, GetAllCouponsQueryVariables>;
export const GetAllSourcesWithTotalsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetAllSourcesWithTotals" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getAllSourcesWithTotals" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "source" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalCoupons" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalInvestors" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetAllSourcesWithTotalsQuery,
  GetAllSourcesWithTotalsQueryVariables
>;
export const GetSourceByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetSourceById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getSourceById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetSourceByIdQuery, GetSourceByIdQueryVariables>;
export const GetCouponsBySourceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetCouponsBySource" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getCouponsBySourceId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourceId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "coupon" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "code" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "incentive" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "totalInvestors" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetCouponsBySourceQuery,
  GetCouponsBySourceQueryVariables
>;
export const GetUserByReferralDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUserByReferral" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUserByReferralAndCoupon" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourceId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "user" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "investorProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "balance" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hubSpotDealStatus",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "coupon" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUserByReferralQuery,
  GetUserByReferralQueryVariables
>;
export const GetUsersByCouponDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetUsersByCoupon" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "couponId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getUsersByCoupon" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "couponId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "couponId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "coupon" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "users" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "investorProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "balance" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hubSpotDealStatus",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GetUsersByCouponQuery,
  GetUsersByCouponQueryVariables
>;
export const FindRolesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "findRoles" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WhereInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "roles" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationOptions" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "take" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "take" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "skip" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "skip" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assignments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "module" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "action" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "admins" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindRolesQuery, FindRolesQueryVariables>;
export const GetRoleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getRole" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "roleById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assignments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "module" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "action" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetRoleQuery, GetRoleQueryVariables>;
export const FindPaginatedUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "findPaginatedUsers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "take" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "skip" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WhereInput" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "searchTerm" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "users" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "paginationOptions" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "take" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "take" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "skip" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "skip" },
                      },
                    },
                  ],
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "searchTerm" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "searchTerm" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalDeposits" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalInvestments" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "investorProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "balance" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "hubSpotDealStatus",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "adminProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "role" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FindPaginatedUsersQuery,
  FindPaginatedUsersQueryVariables
>;
export const FindUsersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "findUsers" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "startDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "endDate" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "where" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "WhereInput" },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "sourceId" },
          },
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "unPaginatedUsers" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "startDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "startDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "endDate" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "endDate" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "where" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "where" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "sourceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "sourceId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "items" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastName" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalDeposits" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalInvestments" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "investorProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "balance" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "adminProfile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "role" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "total" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindUsersQuery, FindUsersQueryVariables>;
export const GetUserByIdDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUserById" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "investorProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "balance" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "solanaWalletAddress" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rewardBalance" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "coupon" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetUserDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getUser" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "userById" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "status" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "adminProfile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "role" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
export const GetMyRoleDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "getMyRole" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "myRole" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "name" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "assignments" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "module" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "action" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetMyRoleQuery, GetMyRoleQueryVariables>;
export const VerifyDeviceDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "verifyDevice" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "deviceId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyDevice" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "email" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "email" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "deviceId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "deviceId" },
                },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<VerifyDeviceQuery, VerifyDeviceQueryVariables>;
